import {
  AuthenticityDiamondMultichome16 as SmallDiamond,
  AuthenticityDiamondMultichrome24 as MediumDiamond,
  AuthenticityDiamond32 as BigDiamond,
} from '@vinted/multichrome-icons'

export const getIconByName = (icon: string) => {
  if (icon === `${SmallDiamond.Title}${SmallDiamond.Size}`) return SmallDiamond
  if (icon === `${MediumDiamond.Title}${MediumDiamond.Size}`) return MediumDiamond
  if (icon === `${BigDiamond.Title}${BigDiamond.Size}`) return BigDiamond

  return null
}
