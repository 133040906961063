import { Icon } from '@vinted/web-ui'
import {
  CancelCircleFilled24,
  CheckCircleFilled24,
  CheckmarkShield24,
  InfoCircleFilled24,
  WarningCircleFilled24,
  InfoCircleFilled64,
  ExclamationCircleFilled64,
  CheckCircleFilled64,
  CancelCircleFilled64,
  CheckmarkShieldFilled64,
} from '@vinted/monochrome-icons'

import { InfoBannerLevel } from 'constants/info-banner'

export const levelIcon24Map: Record<InfoBannerLevel, ComponentProps<typeof Icon>['name']> = {
  [InfoBannerLevel.Info]: InfoCircleFilled24,
  [InfoBannerLevel.Warning]: WarningCircleFilled24,
  [InfoBannerLevel.Danger]: WarningCircleFilled24,
  [InfoBannerLevel.Success]: CheckCircleFilled24,
  [InfoBannerLevel.Error]: CancelCircleFilled24,
  [InfoBannerLevel.Trust]: CheckmarkShield24,
}

export const levelIcon64Map: Record<InfoBannerLevel, ComponentProps<typeof Icon>['name']> = {
  [InfoBannerLevel.Info]: InfoCircleFilled64,
  [InfoBannerLevel.Warning]: ExclamationCircleFilled64,
  [InfoBannerLevel.Danger]: ExclamationCircleFilled64,
  [InfoBannerLevel.Success]: CheckCircleFilled64,
  [InfoBannerLevel.Error]: CancelCircleFilled64,
  [InfoBannerLevel.Trust]: CheckmarkShieldFilled64,
}

export const levelColorMap: Record<InfoBannerLevel, ComponentProps<typeof Icon>['color']> = {
  [InfoBannerLevel.Info]: Icon.Color.Primary,
  [InfoBannerLevel.Warning]: Icon.Color.Expose,
  [InfoBannerLevel.Danger]: Icon.Color.Warning,
  [InfoBannerLevel.Success]: Icon.Color.Success,
  [InfoBannerLevel.Error]: Icon.Color.Warning,
  [InfoBannerLevel.Trust]: Icon.Color.Primary,
}
