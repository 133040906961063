import {
  IconBadgeDto,
  IconExplanationInnerDetailsDto,
  ItemBadgesExplanationDto,
} from 'types/dtos/icon-badge'
import {
  IconBadgeModel,
  IconExplanationInnerDetailsModel,
  ItemBadgesExplanationModel,
} from 'types/models/icon-badge'
import { transformPhotoDto } from 'data/transformers/photo'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformIconBadges = (
  iconBadges?: Array<IconBadgeDto>,
): Array<IconBadgeModel> | undefined => {
  if (!iconBadges) return undefined

  return iconBadges.map(iconBadge => ({
    iconBig: iconBadge.icon_big,
    iconSmall: iconBadge.icon_small,
    label: iconBadge.label,
  }))
}

export const transformExplanationIconBadges = (
  icons: Array<IconExplanationInnerDetailsDto>,
): Array<IconExplanationInnerDetailsModel> => {
  if (!icons.length) return []

  return icons.map(icon => ({
    icon: icon.icon,
    title: icon.title,
    description: icon.description,
    linkText: icon.link_text,
    verificationFee:
      icon.offline_verification_fee && transformCurrencyAmountDto(icon.offline_verification_fee),
  }))
}

export const transformItemBadgesExplanation = (
  itemBadgesExplanation: ItemBadgesExplanationDto,
): ItemBadgesExplanationModel => ({
  title: itemBadgesExplanation.title,
  brand: {
    title: itemBadgesExplanation.brand.title,
    url: itemBadgesExplanation.brand.url,
  },
  sizeTitle: itemBadgesExplanation.size_title,
  photo: transformPhotoDto(itemBadgesExplanation.photo),
  iconBadges: transformExplanationIconBadges(itemBadgesExplanation.icon_badges),
})
