'use client'

import { ComponentProps, MouseEvent, ReactNode } from 'react'
import { Cell, Text } from '@vinted/web-ui'

import { getTestId } from '@marketplace-web/shared/ui-helpers'

type Props = {
  content: ReactNode
  theme: ComponentProps<typeof Cell>['theme']
  /**
   * Adds data-testid attribute to parent and child components.
   * When used, --status and --status-text suffixes applied accordingly.
   */
  testId?: string
  suffix?: ReactNode
  /**
   * Experimental, needed to animate fade out for Favourites action
   * Sets additional classes on the modal content.
   */
  className?: string
  onClick?: () => void
}

const ItemBoxMessage = ({ theme, content, suffix, testId, className, onClick }: Props) => {
  const handleClick = (event: MouseEvent) => {
    if (!onClick) return

    event.preventDefault()
    event.stopPropagation()
    onClick()
  }

  return (
    <div className={className} data-testid={getTestId(testId, 'status')}>
      <Cell
        suffix={suffix}
        styling={Cell.Styling.Narrow}
        theme={theme}
        clickable={!!onClick}
        onClick={handleClick}
      >
        <Text
          text={content}
          type={Text.Type.Caption}
          theme="inverse"
          testId={getTestId(testId, 'status-text')}
          as="p"
        />
      </Cell>
    </div>
  )
}

export default ItemBoxMessage
