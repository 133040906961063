'use client'

import { ReactNode } from 'react'
import { Cell, Spacer, Text } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'

import {
  shouldTrackOnce,
  useAbTest,
  useTrackAbTestCallback,
} from '@marketplace-web/shared/ab-tests'
import { getTestId } from '@marketplace-web/shared/ui-helpers'
import { AbTestExposeEventExtra } from '_libs/common/event-tracker/events'

type Props = {
  renderPriceBreakdown?: ReactNode
  testId?: string
  bumpText?: ReactNode
  description?: { title: ReactNode; subtitle?: ReactNode; exposure?: AbTestExposeEventExtra } | null
  price: string
  oldPrice?: string | null
}

const InformationBreakdown = (props: Props) => {
  const { renderPriceBreakdown, testId, bumpText, description, price, oldPrice } = props

  const abTest = useAbTest(description?.exposure?.test_name || '')
  const trackAbTest = useTrackAbTestCallback()

  const handleDescriptionView = (inView: boolean) => inView && trackAbTest(abTest, shouldTrackOnce)

  const renderBumpedText = () => (
    <Text
      text={bumpText}
      theme="amplified"
      type={Text.Type.Caption}
      testId={getTestId(testId, 'bump-text')}
      as="p"
    />
  )

  const renderAskingPrice = () => (
    <div className="new-item-box__title" data-testid={getTestId(testId, 'title-container')}>
      <div className="title-content">
        <Text
          text={price}
          type={Text.Type.Caption}
          theme="muted"
          testId={getTestId(testId, 'price-text')}
          as="p"
        />
        {oldPrice ? (
          <>
            <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
            <Text
              text={oldPrice}
              type={Text.Type.Caption}
              theme="muted"
              strikethrough
              truncate
              testId={getTestId(testId, 'old-price-text')}
              as="p"
            />
          </>
        ) : null}
      </div>
    </div>
  )

  const renderDescription = () => {
    if (!description) return null

    return (
      <Cell styling={Cell.Styling.Tight} testId={getTestId(testId, 'description')}>
        <InView
          className="u-flexbox u-justify-content-between"
          as="div"
          onChange={handleDescriptionView}
        >
          <div className="new-item-box__description">
            <Text
              text={description.title}
              type={Text.Type.Caption}
              testId={getTestId(testId, 'description-title')}
              as="p"
              truncate
            />
          </div>
          {bumpText && renderBumpedText()}
        </InView>
        <div className="new-item-box__description">
          <Text
            text={description.subtitle}
            type={Text.Type.Caption}
            testId={getTestId(testId, 'description-subtitle')}
            as="p"
            truncate
          />
        </div>
      </Cell>
    )
  }

  return (
    <div>
      <div className="u-flexbox u-align-items-flex-start u-ui-padding-bottom-regular">
        <div className="u-min-width-none u-flex-grow">{description && renderDescription()}</div>
      </div>
      <div>
        {renderAskingPrice()}
        {renderPriceBreakdown}
      </div>
    </div>
  )
}

export default InformationBreakdown
