import { InfoBannerDto } from 'types/dtos/info-banner'
import { InfoBannerModel } from 'types/models/info-banner'

export const transformInfoBannerDto = (infoBanner: InfoBannerDto): InfoBannerModel => ({
  level: infoBanner.level,
  title: infoBanner.title || undefined,
  body: infoBanner.body,
  screenName: infoBanner.screen_name,
  extraNotice: !!infoBanner.extra_notice,
})
