'use client'

import { MouseEvent } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { clickListItemEvent, ClickListItemEventArgs } from '_libs/common/event-tracker/events'
import { GoogleTagManagerEvent } from 'constants/google'
import { ListItemContentType } from 'constants/tracking/content-types'
import { googleTagManagerTrack } from 'data/utils/google'

import ProductItem from './ProductItem'

type Props = {
  tracking: ClickListItemEventArgs
} & ComponentProps<typeof ProductItem>

const TrackedProductItem = ({ onClick, tracking, ...props }: Props) => {
  const { track } = useTracking()

  const handleClick = (event: MouseEvent) => {
    track(clickListItemEvent(tracking))

    if (tracking.contentType === ListItemContentType.Item)
      googleTagManagerTrack(GoogleTagManagerEvent.SelectItem, {
        item_id: tracking.id,
      })

    onClick?.(event)
  }

  return <ProductItem {...props} onClick={handleClick} />
}

export default TrackedProductItem
